<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Selector de Plantillas</h4>
            <div class="small text-muted">Selector de plantillas dinámico para sitio web</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row v-if="arr.templates">                               
        <b-col md="3" v-for="element in arr.templates" :key="element.id">
          <b-card :title="element.name" 
                  :img-src="element.image" 
                  img-alt="Image" 
                  img-top
                  class="crud-content-template">
            <b-card-text v-if="element.description" v-html="element.description"></b-card-text>
            <div v-if="element.url">
              <b-link :href="element.url" target="_blank">
                <b>{{element.url}}</b>
              </b-link>
            </div>
            <div v-if="element.tag">
              <b-badge variant="secondary" v-for="(item, index) in renderTag(element)" :key="index" class="mr-1">
                {{item}}
              </b-badge>
            </div>
            <template #footer>              
              <b-button variant="dark" class="w-100" @click="selectTemplate(element)">Seleccionar</b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                                            
          </b-col>          
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudTemplate',
          elements: {}
        },   
        arr: {
          templates: []
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted() {
      this.load()
    },
    methods: {
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerTemplates()

        result.then((response) => {
          loader.hide()          
          var data = response.data
          this.arr.templates = data
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });                     
      },
      selectTemplate(item) {
        if(item.products) {        
          this.$bvModal.msgBoxConfirm('Esta plantilla (' + item.name  + ') traer productos de ejemplo. ¿Desea pisar los productos actuales por los de ejemplos?', {
            title: 'Selección de Plantilla',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'warning',
            okTitle: 'NO PISAR',
            cancelTitle: 'PISAR',
            footerClass: 'p-2',
            headerBgVariant: 'dark',
            headerTextVariant: 'white',
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {            
              this.confirmSelectTemplate(item, false)        
            } else {
              this.confirmSelectTemplate(item, true)        
            }
          })
          .catch(error => {
            this.$awn.alert(Error.showError(error));
          })    
        } else {
          this.confirmSelectTemplate(item, false)        
        }       
      },   
      confirmSelectTemplate(item, deleteProducts) {
        var msj = ''
        if(deleteProducts) {
          msj='<< TENGA EN CUENTA QUE SE PISARAN LOS PRODUCTOS >>'
        }

        this.$bvModal.msgBoxConfirm('¿Confirmar el uso de la plantilla (' + item.name  + ') para la creación del sitio web?. ' + msj, {
          title: 'Selección de Plantilla',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'CONFIRMAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if(value) {
            let loader = this.$loading.show();
            
            var result = serviceAPI.portabilitySelectTemplate({
              id: item.id,
              deleteProducts: deleteProducts
            });          

            result.then((response) => {                         
              loader.hide()                            
              this.$awn.success("Plantilla " + item.name + " cargada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })    
      },
      renderTag(item) {        
        var tagFinal = []
        if(item.tag) {
          let tag = item.tag.split(",")

          tag.forEach(element => {
            if(element) {
              tagFinal.push(element)
            }
          });
        }
        return tagFinal
      }   
    }
  }
</script>
<style scoped>
  .crud-content-template .card-footer {
    padding: 0%;
  }
  .crud-content-template .card-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;  
  }
  .crud-content-template .card-body {
    height: 180px;
  }    
</style>